<script>
import { CheckCircleIcon } from '@/icons'
import { createHelpers } from "vuex-map-fields";
import { LOGIN, REMOVE_ERRORS } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { VUE_APP_SHOPIFY_LOGIN } from '@/config'
import axios from 'axios'
import OnboardingCard from './registration/OnboardingCard.vue'
import OnboardingHeader from './registration/OnboardingHeader.vue'
import OnboardingHeading from './registration/OnboardingHeading.vue'

const { mapFields } = createHelpers({
  getterType: 'auth/getField',
  mutationType: 'auth/updateField',
});

export default {
  name: 'Login',

  data() {
    return {
      consumerKey: '',
      consumerSecretKey: '',
      email: '',
      errorMessage:'API key & Secret are not valid!',
      interval: '',
      isRefreshAPIDialogVisible: false,
      password: '',
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Not a valid email address'
        },
        required: value => !!value || 'This field is Required',
      },
      shopifyAccountLoginURL: VUE_APP_SHOPIFY_LOGIN,
      showPassword: false,
      showWooLogin: true,
      store_id: '',
      timeout: 2000,
      timer: 3,
      valid: false,
      isContinueInstall: false,
      loadingLogin: false,
    }
  },

  components: {
    CheckCircleIcon,
    OnboardingCard,
    OnboardingHeader,
    OnboardingHeading
  },

  created() {
    this.$store.dispatch(`auth/${REMOVE_ERRORS}`)
  },

  mounted() {
    this.isContinueInstall = this.$route.query && this.$route.query.continue
  },

  computed: {
    ...mapFields(["isPasswordError", "isEmailError"]),
    ...mapGetters('auth', ['errors', 'isLoading']),

    continueInstallation() {
      return this.isContinueInstall && String(this.isContinueInstall.toLowerCase()) === 'true'
    }
  },

  beforeRouteLeave (to, from, next) {
    this.timer = 3
    if (this.interval) {
      clearInterval(this.interval)
    }
    next()
  },

  methods: {
    showPasswordError() {
      return !this.isPasswordError || 'Incorrect password'
    },

    showEmailError() {
      return !this.isEmailError || 'Account not found, try another email'
    },

    startTimer() {
      this.timer -= 1;
      if (this.timer < 0) {
        this.timer = 0;
      }
      if (this.timer === 0) {
        window.location.href = this.shopifyAccountLoginURL
      }
    },

    showWooLoginHandler() {
      this.showWooLogin = true
      this.timer = 3
      if (this.interval) {
        clearInterval(this.interval)
      }
    },

    showShopifyLoginHandler() {
      this.showWooLogin = false
      this.interval = setInterval(this.startTimer, 1000)
    },

    async updateWooAPIKeyHandler() {
      try {
        const response = await axios.post('/wc/2020-01/update-token', {
          store_id: this.store_id,
          consumer_key: this.consumerKey,
          consumer_secret: this.consumerSecretKey
        }).then((data)=>{
          this.isRefreshAPIDialogVisible = false
          this.consumerKey = ''
          this.consumerSecretKey = ''
          this.$router.push({ name: "DashboardPage", params: { "wooUpdate":"true" }})
        });
      } catch (error) {
        this.valid = false
      }
    },

    async loginSubmit(email, password) {
      if (this.valid) {
        this.loadingLogin = true
        this.$store.dispatch(`auth/${LOGIN}`, { email, password }).then((data) => {
          if(data.user && data.user.stores && data.user.stores[0]) {
            this.store_id = data.user.stores[0].id
          }
          /* Discuss with Fei */
          if (!data.woocommerce_healthy && Object.prototype.hasOwnProperty.call(data, "woocommerce_healthy")) {
            this.isRefreshAPIDialogVisible = true
            this.$store.dispatch(`shop/init`)
          } else if (!this.store_id) {
            this.$router.push({ name: "registration.woocommerce.select-store-type" })
          } else {
            this.$router.push({ name: "DashboardPage", params: { "wooUpdate":"true" }})
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    },
  }
}
</script>

<template>
  <section class="onboarding v2">
    <OnboardingHeader />
    <OnboardingHeading v-if="continueInstallation" heading="Welcome Back!" />
    <OnboardingHeading v-else heading="Login to your account" />

    <p class="onboarding-text m-t-2" v-if="continueInstallation">
      Login to continue installing Syncio
    </p>

    <aside class="login-options d-flex justify-center" v-if="!continueInstallation">
      <div class="woo-login d-flex" :class="{ 'active': showWooLogin }" @click="showWooLoginHandler">
        <img src="../assets/images/wo-logo-sm.png" alt="Woo logo" />
        <span class="text">WooCommerce</span>
      </div>
      <div class="shopify-login d-flex" :class="{ 'active': !showWooLogin }" @click="showShopifyLoginHandler">
        <img src="../assets/images/shopify-logo-sm.png" alt="shopify logo" />
        <span class="text">Shopify</span>
      </div>
    </aside>

    <OnboardingCard>
      <template v-if="showWooLogin">
        <v-form v-model="valid">
          <v-text-field
            :rules="[rules.required, rules.email, showEmailError()]"
            autofocus
            class="onboarding-input"
            dense
            label="Email address"
            outlined
            ref="emailAddress"
            type="email"
            v-model="email"
            @input="isEmailError = false" />

          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, showPasswordError()]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            autocomplete="new-password"
            class="onboarding-input password m-y-2"
            dense
            label="Password"
            outlined
            v-model="password"
            @input="isPasswordError = false" />

          <router-link to="/forgot-password" class="label-bold">
            Forgot password?
          </router-link>

          <v-btn
            :disabled="!valid"
            :loading="isLoading"
            @click="loginSubmit(email, password)"
            block
            class="onboarding-btn m-t-2"
            color="primary"
            elevation="0"
            rounded>
            Login
          </v-btn>
        </v-form>
      </template>

      <template v-else>
        <div class="text-center">
          <p class="text-body-1">Redirecting you to Login Via Shopify</p>
          <h3 class="m-b-0 p-t-2">{{ timer }}</h3>
        </div>
      </template>
    </OnboardingCard>

    <ul class="onboarding-complete-list on-login" v-if="continueInstallation">
      <li>
        <CheckCircleIcon />
        One click copy and sync products with Woo and Shopify stores
      </li>
      <li>
        <CheckCircleIcon />
        Real time auto-stock syncing between platforms
      </li>
      <li>
        <CheckCircleIcon />
        Connect to unlimited stores
      </li>
    </ul>

    <aside class="d-flex justify-center m-b-2">
      <router-link to="/registration" class="register-btn">
        Don't have an account? Register for Syncio
      </router-link>
    </aside>

    <v-overlay :value="isRefreshAPIDialogVisible" class="dialog dialog-disconnect">
      <v-card light class="card-rounded not-a-link" width="625px">
        <v-toolbar height="71">
          <v-container class="px-0 d-flex justify-space-between">
            <v-card-title class="h3 pa-0">
              Update Woocommerce store API key
            </v-card-title>
            <v-icon size="30px" color="#ffffff" class="mt-1" @click="isRefreshAPIDialogVisible = false">
              close
            </v-icon>
          </v-container>
        </v-toolbar>

        <div class="dialog-body pa-7">
          <div class="text-left">
            <h4 class="h4 mb-5">Please provide us with a new API key to continue using Syncio.</h4>
            <p>Instructions:</p>
            <ul class="px-0 inside-decimal pl-3">
              <li>Go to Woocommerce -> Settings -> Advanced -> REST API and click "Add key"</li>
              <li>In the submit form, add a description, select a valid user, and select the "Read/Write" permission.</li>
              <li>Click "Generate API key".</li>
              <li>Paste the generated Consumer key and secret key below.</li>
            </ul>
          </div>

          <v-form v-model="valid" class="mt-6">
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-text-field dense outlined label="Consumer Key" v-model="consumerKey" />
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field dense outlined label="Consumer Secret Key" v-model="consumerSecretKey"/>
              </v-col>
              <v-col cols="12" class="pt-0" v-if="!valid">
                <span class="error--text">{{ errorMessage }}</span>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-btn height="45px"  width="100%" class="btn btn-primary mb-2" @click="updateWooAPIKeyHandler">Update</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-overlay>
  </section>
</template>
